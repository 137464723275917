<template>
  <div id="browse-view" class="mt-2">
    <div class="d-flex justify-content-center">
      <!-- <img
        src="@/assets/site/images/loader.gif"
        v-if="loader === true"
        class="img-fluid loader-width"
        alt
      /> -->
    </div>
    <div class="container-fluid">
      <!-- <center>
        <div class="findgft mb-5">
          <h1 class="display-1 mb-0" style="font-weight: 400;"><span> <img class="giftbox-img mr-2"
                src="@/assets/site/images/coupon.svg" alt=""></span> COUPON DEALS <span> <img class="giftbox-img ml-4 "
                src="@/assets/site/images/coupon.svg" alt=""></span></h1>
        </div>
      </center> -->
      <div id="demo" class="carousel slide" data-ride="carousel">
        <!-- Left and right controls -->
        <a class="carousel-control-prev" href="#demo" data-slide="prev">
          <span class="carousel-control-prev-icon"></span>
        </a>
        <a class="carousel-control-next" href="#demo" data-slide="next">
          <span class="carousel-control-next-icon"></span>
        </a>
      </div>
      <div class="my-5">
        <center>
          <!-- <div class="findgft mb-5">
            <h1 class="display-1 mb-0" style="font-weight: 400;"><span> <img class="giftbox-img mr-2"
                  src="@/assets/site/images/brand.svg" alt=""></span> browse brands <span> <img
                  class="giftbox-img ml-4 " src="@/assets/site/images/brand.svg" alt=""></span></h1>
          </div> -->
        </center>
        <div class="row mx-2">
          <div class="col-md-3">
            <div class="sidenav">
              <center>
                <h2 class="my-4">BROWSE</h2>
              </center>
              <a
                href="#"
                :class="{ sidenav_active: selected == browsemenu.id }"
                class="sidenav-active"
                v-for="browsemenu in browsemenus"
                @click="showBrowseProduct(browsemenu.id)"
                >{{ browsemenu.browse_name }}</a
              >
            </div>
          </div>
          <div class="product-slider product-slider-1 col-md-9">
            <div
              id="sm_filterproducts_1610096371335085279"
              class="products-grid mt-4"
            >
              <div class="row">
                <img
                  src="@/assets/site/images/loader.gif"
                  v-if="product_loader === true"
                  class="img-fluid loader-width"
                  alt
                />
                <div
                  class="col-md-3"
                  v-for="product in products"
                  v-if="loadingstatus"
                  :id="product.productId"
                  :product-attribute="product.keywords"
                >
                  <div
                    @click="ViewProduct(product)"
                    class="owl-item active wizard-box"
                  >
                    <div class="item product product-item">
                      <div class="item-inner clearfix">
                        <div class="box-image">
                          <!-- <div class="label-sale pull-right">
                        <b-button size="md" variant="outline-danger" @click="savegift(product)"
                          class="fa fa-gift gift-icon px-3" v-b-popover.hover.top="'Gift'"></b-button>
                      </div> -->
                          <a
                            class="product photo product-item-photo"
                            tabindex="-1"
                          >
                            <span
                              class="product-image-container product-image-container-2281"
                              style="width: 300px"
                            >
                              <span
                                class="product-image-wrapper"
                                style="padding-bottom: 100%"
                              >
                                <span v-if="product.imageurl">
                                  <img
                                    class="wizard-image-photo lazyload"
                                    :src="product.imageurl"
                                    @error="() => (product.imageurl = null)"
                                    data-src=""
                                    loading="lazy"
                                    max-width="300"
                                    max-height="300"
                                    alt=""
                                /></span>
                                <span v-else>
                                  <img
                                    class="wizard-image-photo lazyload"
                                    src="@/assets/site/images/noimage.jpeg"
                                    data-src=""
                                    loading="lazy"
                                    max-width="300"
                                    max-height="300"
                                    alt=""
                                /></span>
                              </span>
                            </span>
                          </a>
                          <div class="bottom-action my-3">
                            <a
                              data-post=""
                              @click.stop="savetoFavourite(product)"
                              class="action towishlist btn-action link-wishlist"
                              data-action="add-to-favourite"
                              title="Add to Favorites"
                            >
                              <span>Add to Favourite</span>
                            </a>
                            <a
                              data-post=""
                              @click.stop="selectOccasion(product)"
                              class="action tocart primary btn-action btn-cart"
                              data-action="add-to-wishlist"
                              title="Add to Wish List"
                            >
                              <span>Add to Wish List</span>
                            </a>
                            <a
                              class="action tocompare btn-action link-compare"
                              @click.stop="compareProduct(product)"
                              data-post=""
                              title="Add to Compare"
                            >
                              <span>Add to Compare</span>
                            </a>

                            <a
                              class="action quickview-handler sm_quickview_handler"
                              title="Quick View"
                              @click.stop="ViewProduct(product)"
                              ><span>Quick View</span></a
                            >
                          </div>
                        </div>
                        <div
                          class="product details product-item-details box-info"
                        >
                          <h2 class="product-item-name">
                            <a class="product-item-link">
                              {{ product.productname }}
                            </a>
                          </h2>

                          <div
                            class="price-box price-final_price"
                            data-role="priceBox"
                            data-product-id="2280"
                            data-price-box="product-id-2280"
                          >
                            <span
                              class="price-container price-final_price tax weee"
                            >
                              <span
                                id="product-price-2280"
                                data-price-amount="450"
                                data-price-type="finalPrice"
                                class="price-wrapper"
                                ><span class="price">
                                  $ {{ product.price }}</span
                                ></span
                              >
                            </span>
                          </div>
                          <div class="product-reviews-summary short">
                            <div class="rating-summary">
                              <span class="label"><span>Rating:</span></span>
                              <div
                                class="rating-result"
                                id="rating-result_2280"
                                title="87%"
                              >
                                <span style="width: 87%"><span>87%</span></span>
                              </div>
                            </div>
                            <div class="reviews-actions">
                              <a class="action view">
                                &nbsp;<span>Review </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="products.length"
        class="row my-4 d-flex justify-content-center"
      >
        <app-pagination
          :currentPage="page"
          :hasNextPage="totalPages !== page"
          :changePage="changePage"
        ></app-pagination>
        <!-- <div class="col-md-12">
          <button
            class="btn btn-danger float-right col-md-2"
            @click="showBrowseProduct(selected, currentPage + 1)"
          >
            More <i class="fa fa-arrow-down" aria-hidden="true"></i>
          </button>
        </div> -->
      </div>
    </div>
    <div>
      <occasion-modal v-on:savetoWishlist="savetoWishlist"></occasion-modal>
    </div>
  </div>
</template>

<script>
import OccasionModal from "@/views/site/OccasionModal";
import AppPagination from "@/views/site/blocks/Pagination";

export default {
  name: "BrowseView",
  // props: ["products"],
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Discover an extensive range of products to browse through, featuring exclusive deals and top brands! Enjoy an easy shopping experience with personalized product suggestions and seamless navigation to find what you love.",
      },
      {
        name: "keywords",
        content:
          "browse products, online shopping, product deals, browse brands, gift ideas, product reviews, favorites, wishlist, product comparison, exclusive offers, shopping categories, gift options, user-friendly shopping, seasonal products, special promotions",
      },
    ],
  },
  data() {
    return {
      products: [],
      nextPageProducts: [],
      prevPageProducts: [],
      search_loading: false,
      loader: true,
      selected_friend: null,
      browsemenus: [],
      selected: 1,
      loadingstatus: true,
      product_loader: false,
      occasion_id: null,
      wishlist_product: null,
      page: 1,
      totalPages: null,
      request_body: null,
      currentPage: 1,
    };
  },
  components: {
    OccasionModal,
    AppPagination,
  },
  created() {
    // this.products = this.$attrs.browseProducts.items;
    // this.selected_friend = this.$attrs.user_contact.id;
    // console.log('route',this.$router.params);
    // console.log(this.$attrs.products);
    this.$store.state.wizard_type = "User";
    this.$store.state.pet_id = null;
    this.$store.state.occasion_id = null;
    this.browse_id = this.$store.state.browse_id;
    this.browsemenus = this.$store.state.browsemenus;
    this.getBrowsmenus();
  },
  methods: {
    getAllrange() {},
    getBrowsmenus() {
      var fetch_url =
        process.env.VUE_APP_URL + "customer/browsemenu/getBrowseMenu";
      fetch(fetch_url, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          // Authorization: "Bearer " + api_token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // this.loader = false;
          this.browsemenus = data.browsemenu;
          if (this.browse_id == null) {
            this.showBrowseProduct(data.browsemenu[0].id);
          } else {
            this.showBrowseProduct(this.browse_id);
          }
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    changePage(page) {
      !this.search_loading && this.moreProducts(page);

      window.scrollTo({
        top: 100,
        behavior: "smooth",
      });
    },
    moreProducts(page) {
      this.search_loading = true;
      const country_code = localStorage.getItem("countryCode");
      const countryCode = JSON.parse(country_code);
      let fetchPage;
      let pageStatus = "";

      if (page === 1) {
        if (this.page > page) {
          this.page = 1;
          this.nextPageProducts = JSON.parse(JSON.stringify(this.products));
          this.products = this.prevPageProducts;
        }
        fetchPage = 2;
        pageStatus = "initial";
      } else if (page > this.page) {
        this.prevPageProducts = JSON.parse(JSON.stringify(this.products));
        this.products = JSON.parse(JSON.stringify(this.nextPageProducts));
        fetchPage = page + 1;
        this.page = page;
        pageStatus = "next";
      } else if (page < this.page) {
        this.nextPageProducts = JSON.parse(JSON.stringify(this.products));
        this.products = JSON.parse(JSON.stringify(this.prevPageProducts));
        fetchPage = page - 1;
        this.page = page;
        pageStatus = "prev";
      }

      const fetch_url =
        process.env.VUE_APP_URL +
        "customer/products/getBrowseProductsV2/" +
        this.selected;
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          // Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          countryCode: countryCode,
          page: fetchPage,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (pageStatus == "initial")
            this.nextPageProducts = data.response.item;
          else if (pageStatus == "next") {
            this.nextPageProducts = data.response.item;
          } else if (pageStatus == "prev") {
            this.prevPageProducts = data.response.item;
          }

          this.search_loading = false;
          this.totalPages = data.response.TotalPages;
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    savegift(product) {
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        alert("Please log in to continue");
      } else {
        //Save or update User Details
        var token = localStorage.getItem("userData");
        var userdata_array = JSON.parse(token);
        var api_token = userdata_array.token;
        var user_id = userdata_array.user.id;
        var fetch_url = process.env.VUE_APP_URL + "customer/savegift";
        fetch(fetch_url, {
          method: "post",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
          body: JSON.stringify({
            product: product,
            user_id: user_id,
            product_id: product.productId,
            user_contact_id: this.selected_friend,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.loader = false;
            this.errors = [];
            if (data.status === true) {
              this.loader = false;
              alert("Item added to Gift Box");
              this.$swal.fire("Good to Go", data.message, "success");
            } else if (data.status === false) {
              this.loader = false;
              this.$swal.fire("Error", data.message, "error");
            } else {
              this.loader = false;
              this.errors = data.errors;
            }
          })
          .catch(function(err) {
            this.$swal("Product", "Error : " + err.message, "error");
          });
      }
    },
    selectOccasion(product) {
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        alert("Please log in to continue");
      } else {
        this.wishlist_product = product;
        this.$bvModal.show("occasion-modal");
      }
    },
    savetoWishlist(occasion) {
      //Save or update User Details
      this.$bvModal.hide("occasion-modal");
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.token;
      var user_id = userdata_array.user.id;
      var fetch_url = process.env.VUE_APP_URL + "customer/userwishlist";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          product_id: this.wishlist_product.productId,
          //product_name:product.productName,
          product: this.wishlist_product,
          //price:product.price.text,
          user_id: user_id,
          wishlist_type: "User",
          occasion_id: occasion.id,
          user_action: "AddToWishlist",
          clickstream_data: null,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          if (data.status === true) {
            this.$swal.fire("Good to Go", data.message, "success");
          } else if (data.status === false) {
            this.loader = false;
            this.$swal.fire("Error", data.message, "error");
          } else {
            this.loader = false;
            this.errors = data.errors;
          }
        })
        .catch(function(err) {
          this.$swal("Product", "Error : " + err.message, "error");
        });

      var fetch_url =
        process.env.VUE_APP_URL +
        "customer/products/saveAffilativeNetwork/" +
        this.wishlist_product.productId;
      fetch(fetch_url, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
      })
        .then((res) => res.json())
        .then((data) => {});

      this.wishlist_product = null;
    },
    compareProduct(product) {
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        alert("Please log in to continue");
      } else {
        //Save or update User Details
        var token = localStorage.getItem("userData");
        var userdata_array = JSON.parse(token);
        var api_token = userdata_array.token;
        var user_id = userdata_array.user.id;
        var fetch_url = process.env.VUE_APP_URL + "customer/compare";
        fetch(fetch_url, {
          method: "post",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
          body: JSON.stringify({
            product_id: product.productId,
            //product_name:product.productName,
            product: product,
            //price:product.price.text,
            user_id: user_id,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.loader = false;
            this.errors = [];
            if (data.status === true) {
              this.loader = false;
              this.$swal.fire("Good to Go", data.message, "success");
            } else if (data.status === false) {
              this.loader = false;
              this.$swal.fire("Error", data.message, "error");
            } else {
              this.loader = false;
              this.errors = data.errors;
            }
          })
          .catch(function(err) {
            this.$swal("Product", "Error : " + err.message, "error");
          });
      }
    },
    ViewProduct(product) {
      this.$store.state.pet_id = null;
      const id = Math.random()
        .toString(36)
        .slice(2);
      this.$router.push({
        path: "/single-product/product/" + id,
        query: { singleproduct: product },
      });
    },
    showBrowseProduct(browsemenu, currentPage = 1) {
      this.$store.state.browse_id = browsemenu;

      if (currentPage === 1) {
        this.product_loader = true;
        this.loadingstatus = false;
      }

      this.errors = [];
      this.selected = browsemenu;
      var country_code = localStorage.getItem("countryCode");
      var countryCode = JSON.parse(country_code);
      let method_type = "";
      let fetch_url = "";
      method_type = "post";
      fetch_url =
        process.env.VUE_APP_URL +
        "customer/products/getBrowseProductsV2/" +
        browsemenu;
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          // Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          countryCode: countryCode,
          page: 1,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (currentPage === 1) this.products = data.response.item;
          else this.products = [...this.products, ...data.response.item];
          this.product_loader = false;
          this.loadingstatus = true;
          this.currentPage = 1;
          this.totalPages = data.response.TotalPages;
          this.moreProducts(1);
        });
    },
    savetoFavourite(product) {
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        alert("Please log in to continue");
      } else {
        //Save or update User Details
        var token = localStorage.getItem("userData");
        var userdata_array = JSON.parse(token);
        var api_token = userdata_array.user.api_token;
        var user_id = userdata_array.user.id;
        var fetch_url = process.env.VUE_APP_URL + "customer/favourite";
        fetch(fetch_url, {
          method: "post",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
          body: JSON.stringify({
            product_id: product.productId,
            product: product,
            user_id: user_id,
            clickstream_data: null,
            user_action: "AddToFav",
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.loader = false;
            this.errors = [];
            if (data.status === true) {
              this.loader = false;
              this.$swal.fire("Good to Go", data.message, "success");
            } else if (data.status === false) {
              this.loader = false;
              this.$swal.fire("Error", data.message, "error");
            } else {
              this.loader = false;
              this.errors = data.errors;
            }
          })
          .catch(function(err) {
            this.$swal("Product", "Error : " + err.message, "error");
          });
      }
    },
  },
};
</script>
